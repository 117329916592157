import env from "react-dotenv";
import getToken from "../hooks/getToken";
import { expiredSession } from "../hooks/accountHandler";

const FINAL_API_URL = process.env.REACT_APP_API_URL;

const ApiService = {
  account: {
    async login(username, password, rememberMe) {
      try {
        const response = await _fetch("/api/v1/accounts/login", "POST", {
          username,
          password,
          rememberMe,
        });
        // if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    },
    async register(firstName, lastName, emailAddress, department, username, password) {
      try {
        const response = await _fetch("/api/v1/accounts/register", "POST", {
          first_name: firstName,
          last_name: lastName,
          email_address: emailAddress,
          department,
          username,
          password,
        });
        if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    },

    async changePassword(oldPassword, newPassword) {
      try {
        const response = await _fetch("/api/v1/accounts/change-password", "POST", {
          oldPassword,
          newPassword,
        });
        // if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    },

    async getUserDetails() {
      try {
        const response = await _fetch("/api/v1/accounts/me", "GET");
        // if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    },
    async updateUserDetails(request) {
      try {
        const response = await _fetch("/api/v1/accounts/me", "PUT", request, true);
        // if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    },
  },
  token: {
    async verifyToken(token) {
      try {
        const response = await _fetch("/api/v1/tokens/verify", "POST", {
          token,
        });

        if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
  },
  conversation: {
    async getAllConversations() {
      try {
        const response = await _fetch("/api/v1/conversations", "GET");

        if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },

    async getConversationDetail(conversationId) {
      try {
        const response = await _fetch(`/api/v1/conversations/${conversationId}`, "GET");

        if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
    async createConversation(request) {
      try {
        const response = await _fetch(`/api/v1/conversations`, "POST", request, true);

        // if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },

    async updateConversation(request, conversationId) {
      try {
        const response = await _fetch(`/api/v1/conversations/${conversationId}`, "PATCH", request);

        // if (!response.ok) expiredSession();
        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
    async updateConversationDetail(request, conversationId) {
      try {
        const response = await _fetch(`/api/v1/conversations/${conversationId}`, "PUT", request, true);

        // if (!response.ok) expiredSession();
        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
    async getConversationActionByConversationId(conversationId) {
      try {
        const response = await _fetch(`/api/v1/conversations/actions/${conversationId}`, "GET");

        if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
  },
  message: {
    async getConversationMessages(conversationId, page = 1, limit = 30) {
      try {
        const response = await _fetch(`/api/v1/messages/${conversationId}?page=${page}&limit=${limit}`, "GET");

        if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
    async sendMessage(request, conversationId) {
      try {
        const response = await _fetch(`/api/v1/messages/${conversationId}`, "POST", request, true);

        if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },

    async searchMessage(request, conversationId) {
      try {
        const response = await _fetch(`/api/v1/messages/search/${conversationId}`, "POST", request);
        if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },

    async deleteMessage(conversationId, messageId) {
      try {
        const response = await _fetch(`/api/v1/messages/${conversationId}/${messageId}`, "DELETE");
        // if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },

    async getThreadMessages(conversationId, messageId) {
      try {
        const response = await _fetch(`/api/v1/messages/thread/${conversationId}/${messageId}`, "GET");
        // if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
    async replyThreadMessage(request, conversationId, messageId) {
      try {
        const response = await _fetch(`/api/v1/messages/${conversationId}/${messageId}`, "POST", request, true);

        if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },

    async getMessagePosition(conversationId, messageId) {
      try {
        const response = await _fetch(`/api/v1/messages/${conversationId}/${messageId}`, "GET");
        // if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },

    async addRemoveReaction(conversationId, messageId, request) {
      try {
        const response = await _fetch(`/api/v1/messages/${conversationId}/${messageId}`, "PUT", request);
        // if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
    async globalSearchMessage(request) {
      try {
        const response = await _fetch(`/api/v1/messages/global/search/`, "POST", request);
        if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
  },
  users: {
    async getAllusers() {
      try {
        const response = await _fetch(`/api/v1/users`, "GET");

        if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
    async getAllNotifications() {
      try {
        const response = await _fetch(`/api/v1/users/notifications`, "GET");

        if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
  },
  conversationMembers: {
    async updateConversationMember(conversation_id, conversation_member_id, request) {
      try {
        const response = await _fetch(
          `/api/v1/conversation-member/${conversation_id}/${conversation_member_id}`,
          "PATCH",
          request,
          false
        );
        if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },

    async addConversationMember(conversation_id, request) {
      try {
        const response = await _fetch(`/api/v1/conversation-member/${conversation_id}`, "PUT", request, false);
        // if (!response.ok) expiredSession();
        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
  },
  files: {
    async getAllConversationFiles(conversation_id) {
      try {
        const response = await _fetch(`/api/v1/files/${conversation_id}`, "GET");
        if (!response.ok) expiredSession();

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
    async getConversationImage(imageFileName) {
      try {
        const response = await _fetch(`/assets/message-attachment/${imageFileName}`, "GET");

        const result = await response.blob();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
  },
  notifications: {
    async readDeleteNotification(request, notification_id) {
      try {
        const response = await _fetch(`/api/v1/notifications/${notification_id}`, "POST", request);

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
  },
  forms: {
    async getMyForms() {
      try {
        const response = await _fetch(`/api/v1/forms`, "GET");

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
    async getFormDetailById(form_id) {
      try {
        const response = await _fetch(`/api/v1/forms/${form_id}`, "GET");

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
    async submitFormRequest(form_id, request) {
      try {
        const response = await _fetch(`/api/v1/forms/${form_id}`, "POST", request, true);

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
    async getMyRequests() {
      try {
        const response = await _fetch(`/api/v1/forms/me/requests`, "GET");

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
    async getFormRequestDetail(request_type, link_id) {
      try {
        const response = await _fetch(`/api/v1/forms/me/requests/${request_type}/${link_id}`, "GET");

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
    async getApprovalRequestsList() {
      try {
        const response = await _fetch(`/api/v1/forms/me/approvals`, "GET");

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
    async approveDenyRequest(form_id, request_id, status) {
      try {
        const response = await _fetch(`/api/v1/forms/me/requests`, "POST", { form_id, request_id, status });

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
    async getPagePermissions() {
      try {
        const response = await _fetch(`/api/v1/forms/me/permissions`, "GET");

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
    async getRequestsHistory(query = "") {
      try {
        const response = await _fetch(`/api/v1/forms/me/requests/history?query=${query}`, "GET");

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
    async getDashboardReports() {
      try {
        const response = await _fetch(`/api/v1/forms/me/reports`, "GET");

        const result = await response.json();
        return result;
      } catch (err) {
        console.error("Error:", err);
        throw err;
      }
    },
  },
};

function _fetch(apiUrl, reqMethod, reqBody, isFormData = false) {
  const token = getToken("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  let body;
  if (isFormData) {
    body = reqBody;
  } else {
    headers["Content-Type"] = "application/json";
    body = JSON.stringify(reqBody);
  }

  return fetch(`${FINAL_API_URL}${apiUrl}`, {
    method: reqMethod,
    headers: headers,
    body: body,
  });
}

export default ApiService;
