import { formatDistanceToNow } from "date-fns";
import React from "react";

export const convertTo12HourFormat = (timestamp) => {
  const date = new Date(timestamp);

  let hours = date.getHours();
  const minutes = date.getMinutes();

  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12;

  const minutesStr = minutes < 10 ? "0" + minutes : minutes;

  const timeStr = `${hours}:${minutesStr} ${ampm}`;

  return timeStr;
};

export const highlightText = (text, highlight) => {
  const regex = new RegExp(`(${highlight})`, "gi");
  return text.split(regex).map((part, index) => (regex.test(part) ? <mark key={index}>{part}</mark> : part));
};

export const convertTo12HourPlusDateFormat = (timestamp) => {
  const date = new Date(timestamp);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes();

  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12;

  const minutesStr = minutes < 10 ? "0" + minutes : minutes;

  const timeStr = `${hours}:${minutesStr} ${ampm}`;
  const dateStr = `${month} ${day}, ${year}`;

  return `${dateStr} ${timeStr}`;
};

export const getTimeAgo = (timestamp) => {
  if (!timestamp) return;
  let formattedTimeStamp = formatDistanceToNow(new Date(timestamp), { addSuffix: false });

  if (formattedTimeStamp.includes("less than a minute")) {
    formattedTimeStamp = "now";
  } else {
    formattedTimeStamp = formattedTimeStamp
      .replace(/ about /, "")
      .replace(/ minutes?/, "m")
      .replace(/ hours?/, "h")
      .replace(/ days?/, "d")
      .replace(/ months?/, "mo")
      .replace(/ years?/, "y")
      .replace(/ seconds?/, "s");
  }
  return formattedTimeStamp;
};

export const dateToWords = (dateString) => {
  const date = new Date(dateString);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();

  return `${month} ${day}`;
};

export const trimWord = (word, maxLength) => {
  if (word.length > maxLength) {
    return word.slice(0, maxLength) + "...";
  } else {
    return word;
  }
};
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0];
};

export const groupReactions = (messageReactions) => {
  return messageReactions.reduce((acc, reaction) => {
    const { reaction_type } = reaction;
    if (!acc[reaction_type]) {
      acc[reaction_type] = [];
    }
    acc[reaction_type].push(reaction);
    return acc;
  }, {});
};

export const formatBoldText = (text) => {
  const regex = /@@(.*?)@@/g;

  let match;
  let lastIndex = 0;
  const formattedParts = [];

  while ((match = regex.exec(text)) !== null) {
    const beforeMatch = text.slice(lastIndex, match.index);
    const beforeMatchParts = beforeMatch.split("\n").map((part, index) => (
      <React.Fragment key={`${match.index}-${index}`}>
        {index > 0 && <br />}
        {part}
      </React.Fragment>
    ));
    formattedParts.push(...beforeMatchParts);

    const matchedText = match[1];
    formattedParts.push(<strong key={match.index}>{`@${matchedText}`}</strong>);

    lastIndex = regex.lastIndex;
  }

  const remainingText = text
    .slice(lastIndex)
    .split("\n")
    .map((part, index) => (
      <React.Fragment key={`remaining-${index}`}>
        {index > 0 && <br />}
        {part}
      </React.Fragment>
    ));
  formattedParts.push(...remainingText);

  return formattedParts;
};

export const getCurrentDateToday = () => {
  const today = new Date();
  const numberOfDaysToAdd = 2;
  const date = today.setDate(today.getDate() + numberOfDaysToAdd);
  const defaultValue = new Date(date).toISOString().split("T")[0];

  return defaultValue;
};
export const formatToPeso = (amount) => {
  return new Intl.NumberFormat("en-PH", {
    style: "currency",
    currency: "PHP",
    minimumFractionDigits: 2,
  }).format(amount);
};
