import React, { useState, useContext, useEffect, useRef } from "react";
import "../../styles/offsetting.css";
import loader2 from "../../images/loader_2.gif";
import Offsetting from "./reusable/offsetting";

function OffsettingForm() {
  const [offSetting, setOffSetting] = useState([
    {
      id: new Date().getTime(),
      date: new Date(),
      accumulatedHrs: 0,
    },
  ]);

  const addOffsetting = () => {
    if (offSetting.length > 0) {
      const newOffsetting = {
        id: new Date().getTime(),
        date: new Date(),
        accumulatedHrs: 0,
      };
      setOffSetting([...offSetting, newOffsetting]);
    }
  };

  const removeOffsetting = (id) => {
    if (!offSetting.length < 1) {
      setOffSetting(offSetting.filter((offset) => offset.id !== id));
    }
  };

  return (
    <div className='epr-form'>
      <div className='title'>
        <h3>Offsetting Form</h3>
      </div>
      <div className='form-body'>
        <div className='offsetting-container'>
          {offSetting.map((_, index) => (
            <Offsetting key={index} addOffsetting={addOffsetting} removeOffsetting={removeOffsetting} offset={_} />
          ))}
        </div>
        <div className='form-fields'>
          <label htmlFor=''>Total of Extended Hours Accumulated</label>
          <input type='text' />
        </div>
        <div className='form-fields'>
          <label htmlFor=''>
            Date of Offset <span className='required'>*</span>
          </label>
          <input type='date' />
        </div>
        <div className='form-fields'>
          <label htmlFor=''>
            Type of Offset <span className='required'>*</span>
          </label>
          <select name='' id=''>
            <option value=''>1st Half</option>
            <option value=''>2nd Half</option>
          </select>
        </div>
        <div className='submit-action-btn'>
          <button className='search-btn submit-request'>
            <span className='form-btn-txt'>Submit Request</span>
            <img src={loader2} className='form-loader' style={{ display: "none" }} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default OffsettingForm;
