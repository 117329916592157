import { useState } from "react";
import "../styles/login.css";
import BuildhubBg from "../images/bg.png";
import BuildhubImg from "../images/buildhub.png";
import BuildhubImgLeft from "../images/buildhub-login-chat.png";
import { common } from "../util/common";
import showSwal from "../components/swal";
import ApiService from "../api/apiService";
import { useNavigate } from "react-router-dom";
import { saveTokenToCookies, saveUserDetailsToLocalStorage } from "../hooks/accountHandler";
import spinner from "../images/loader.gif";

function LoginPage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [isRememberMe, setIsRememberMe] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const login = async () => {
    try {
      if (!username || !password) return setError("Email Address & Password cannot be blank.");
      setLoading(true);
      const response = await ApiService.account.login(username, password, isRememberMe);
      const { success, message, token, userDetails } = response;
      setLoading(false);
      if (!success) return setError(message);
      saveTokenToCookies(token, isRememberMe);
      saveUserDetailsToLocalStorage(userDetails);

      navigate("/");
    } catch (err) {
      alert(err);
      setLoading(false);
      showSwal(common.errors.LOGIN_FAILED);
    }
  };

  return (
    <div className='login-container' style={{ backgroundImage: `url(${BuildhubBg})` }}>
      <div className='login-image'>
        <img src={BuildhubImgLeft} />
      </div>
      <div className='login-form'>
        <img src={BuildhubImg} className='form-logo' />
        {error !== "" && (
          <div className='error-container-msg'>
            <p>{error}</p>
          </div>
        )}
        {loading && <img src={spinner} className='loading-spinner' />}
        <form action=''>
          <div className='email-container login-form-element'>
            {/* <label htmlFor='email'>Email Address</label> */}
            <input
              placeholder='Email Address'
              type='text'
              name='email'
              id='email'
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className='password-container login-form-element'>
            {/* <label htmlFor='password'>Password</label> */}
            <div className='input-wrapper'>
              <input
                placeholder='Password'
                type={passwordVisible ? "text" : "password"}
                name='password'
                className='password-input'
                id='password'
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div className='reveal-password' onClick={togglePasswordVisibility}>
                {passwordVisible ? <h2>Hide Password</h2> : <h2>Show Password</h2>}
                {/* <i
                  className={`reveal-password fa ${passwordVisible ? "fa-eye-slash" : "fa-eye"} fa-3x`}
                  aria-hidden='true'
                ></i> */}
              </div>
            </div>
          </div>
          <div className='remember-me'>
            <input type='checkbox' id='remember-me' onChange={(e) => setIsRememberMe(e.target.checked)} />
            <label htmlFor='remember-me' className='remember-me-label'>
              Keep me logged in
            </label>
          </div>
          <div className='button-login-container login-form-element'>
            <button
              disabled={loading}
              type='submit'
              onClick={(e) => {
                e.preventDefault();
                login();
              }}
            >
              LOGIN
            </button>
          </div>
        </form>
        <div className='copyright-footer'>
          <p>Buildhub &#169; 2024 All Rights Reserved. </p>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
