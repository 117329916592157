import "../styles/style.css";
import "../styles/chat-sidebar-item.css";
import emptyImage from "../images/empty.jpg";
import { convertTo12HourFormat, trimWord, getTimeAgo } from "../util/helperFunctions";
import React, { useState, useRef, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import ApiService from "../api/apiService";
import showSwal from "./swal";
import { common } from "../util/common";
import { Link } from "react-router-dom";
import { ChatContext, WebSocketContext } from "../context/contexts";

function ChatSideBarItem(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { chatDetail, linkConversation, setConnectedRoom } = props;
  const { id, name, image, last_message, is_pinned, unread_count } = chatDetail;
  const { id: sender_id, content, updatedAt, sender } = last_message;
  const pinRef = useRef(null);
  const leaveRef = useRef(null);

  const WS = useContext(WebSocketContext);
  const Chat = useContext(ChatContext);

  const setConversationDetail = Chat.setConversationDetail;

  const senderName = sender.display_name;
  const senderChat = content;

  const [showDropdown, setShowDropdown] = useState(false);

  const handleItemClick = () => {
    WS.connectWSRoom(id);
    setConversationDetail(id);
  };

  const handlePin = async () => {
    const pinBtnId = pinRef.current.getAttribute("data-value");
    const _pinConversation = await ApiService.conversation.updateConversation({ action: "pin" }, pinBtnId);
    const { success, conversation } = _pinConversation;
    if (!success) showSwal(common.errors.PIN_CONVERSATION_ERROR);
  };

  const handleLeave = async () => {
    const leaveBtnId = leaveRef.current.getAttribute("data-value");
    const _leaveConversation = await ApiService.conversation.updateConversation({ action: "leave" }, leaveBtnId);
    const { success, msg } = _leaveConversation;
    if (!success) showSwal(common.errors.LEAVE_CONVERSATION_ERROR);
    setConversationDetail(null);
  };

  const FINAL_RESOURCES_URL = `${process.env.REACT_APP_IMAGES_DATA_RESOURCES_URL}/${image}`;
  const conversationImage = image.includes("empty-filler-image.jpg") ? emptyImage : FINAL_RESOURCES_URL;
  const timeStamp = getTimeAgo(updatedAt);

  let finalSenderName,
    finalSenderChat,
    finalChatContent = "";
  if (senderName && senderChat) {
    finalSenderName = senderName.split(" ")[0];
    finalSenderChat = trimWord(senderChat, 20);
    finalChatContent = `${finalSenderName}: ${finalSenderChat} • ${timeStamp}`;
  }

  return (
    <li
      className={`inbox__item ${unread_count > 0 ? "inbox__item--unread" : ""}`}
      data-value={id}
      data-name={name}
      data-sid={id}
    >
      {is_pinned && <i className='fa fa-thumb-tack' aria-hidden='true'></i>}
      <Link to={`conversations/${id}`}>
        <div className='sidebar-item' onClick={handleItemClick}>
          <input type='hidden' className='js-input-sellerId' value={id} />
          <img src={conversationImage} alt='Seller image' className='inbox__item-img' />
          <div className='inbox__item-details'>
            <span className='inbox__name'>
              <b>{name}</b>
            </span>
            <span className='inbox__message'>
              {/* Display last message here */}
              {finalChatContent}
            </span>
          </div>
        </div>
      </Link>
      <div className='inbox__item-details inbox__item-details--2'>
        {/* <span className='inbox__message-time'>{timeStamp}</span> */}
        <div className='dropdown dropdown--hover' onClick={() => setShowDropdown(!showDropdown)}>
          <button className='dropdown-btn'>
            <i className='fa fa-ellipsis-v'></i>
          </button>
          <div className={`dropdown-content ${showDropdown ? "show" : ""}`}>
            <button data-value={id} onClick={handlePin} ref={pinRef}>
              {is_pinned ? "Unpin" : "Pin"}
            </button>
            <button data-value={id} onClick={handleLeave} ref={leaveRef}>
              Leave
            </button>
          </div>
        </div>
        {unread_count > 0 ? <span className='inbox__unread-count'>{unread_count}</span> : null}
      </div>
    </li>
  );
}

export default ChatSideBarItem;
