import heartReaction from "../../images/reactions/heart-reaction.png";
import hahaReaction from "../../images/reactions/haha-reaction.png";
import likeReaction from "../../images/reactions/like-reaction.png";
import thanksReaction from "../../images/reactions/thanks-reaction.png";
import sadReaction from "../../images/reactions/sad-reaction.png";
import React, { useState, useRef, useEffect, useContext } from "react";
import { ChatContext, WebSocketContext } from "../../context/contexts";
import ApiService from "../../api/apiService";
import { common } from "../../util/common";
import showSwal from "../swal";

function ReactionOptions({ chat_id, receiver, reaction, userDetails, toggleShowReactionOptions }) {
  const Chat = useContext(ChatContext);
  const WS = useContext(WebSocketContext);

  const handleReactionClick = async (e) => {
    const target = e.target;

    const reactionType = target.getAttribute("reaction");

    const reactionResponse = await ApiService.message.addRemoveReaction(Chat.storedConversationId, chat_id, {
      reaction_type: reactionType,
    });

    const { success, message_reaction } = reactionResponse;
    if (!success) return showSwal(common.errors.ADD_REMOVE_REACTION_ERROR);
    WS.websocketInstance.sendMsgReaction(
      { message: message_reaction, type: "user-reactions-chat" },
      Chat.storedConversationId
    );
    toggleShowReactionOptions();
  };

  const isHahaReact = reaction?.haha?.find((reactions) => reactions.user_id === userDetails.id);
  const isHeartReact = reaction?.heart?.find((reactions) => reactions.user_id === userDetails.id);
  const isThanksReact = reaction?.thanks?.find((reactions) => reactions.user_id === userDetails.id);
  const isLikeReact = reaction?.like?.find((reactions) => reactions.user_id === userDetails.id);
  const isSadReact = reaction?.sad?.find((reactions) => reactions.user_id === userDetails.id);

  return (
    <div className={`reaction-options ${receiver ? "reaction-options-receiver" : ""}`}>
      <img
        src={heartReaction}
        reaction='heart'
        className={`like-reaction ${isHeartReact ? "pulse pulse-bg" : ""}`}
        onClick={(e) => handleReactionClick(e)}
      />
      <img
        src={hahaReaction}
        reaction='haha'
        className={`like-reaction ${isHahaReact ? "pulse pulse-bg" : ""}`}
        onClick={(e) => handleReactionClick(e)}
      />
      <img
        src={thanksReaction}
        reaction='thanks'
        className={`like-reaction ${isThanksReact ? "pulse pulse-bg" : ""}`}
        onClick={(e) => handleReactionClick(e)}
      />
      <img
        src={likeReaction}
        reaction='like'
        className={`like-reaction ${isLikeReact ? "pulse pulse-bg" : ""}`}
        onClick={(e) => handleReactionClick(e)}
      />
      <img
        src={sadReaction}
        reaction='sad'
        className={`like-reaction ${isSadReact ? "pulse pulse-bg" : ""}`}
        onClick={(e) => handleReactionClick(e)}
      />
    </div>
  );
}

export default ReactionOptions;
