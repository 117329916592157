import React, { useState, useEffect, useRef, useContext } from "react";
import "../styles/style.css";
import { convertTo12HourFormat, trimWord } from "../util/helperFunctions";
import emptyImage from "../images/empty.jpg";
import env from "react-dotenv";
import MessageComponent from "./message";
import { ChatContext } from "../context/contexts";
import likeReaction from "../images/reactions/like-reaction.png";
import heartReaction from "../images/reactions/heart-reaction.png";
import hahaReaction from "../images/reactions/haha-reaction.png";
import emojiReaction from "../images/emotion.png";
import ReactionsIndicator from "./reusable/reactions-indicator";
import ReactionOptions from "./reusable/reaction-options";
import { groupReactions } from "../util/helperFunctions";

function MessageReceiver(props) {
  const Chat = useContext(ChatContext);

  const { messageDetail, userDetails, handleImageClick, toggleShowChatReply, setChatReplyData } = props;
  const {
    id: chat_id,
    conversation_id,
    content,
    createdAt,
    is_deleted,
    message_attachment,
    reply_to_message_detail,
    user,
    thread_replies,
    message_reactions,
  } = messageDetail;

  const [isShowChatOptions, setShowChatOptions] = useState(false);

  const [____isDeleted, ____setIsDeleted] = useState(false);

  const dropdownRef = useRef(null);

  const [showReactionOptionList, setShowReactionOptionList] = useState(false);

  const groupedReaction = groupReactions(message_reactions);

  const toggleShowReactionOptions = () => {
    setShowReactionOptionList((prevState) => !prevState);
  };

  const handleOnClickReply = () => {
    const replyData = {
      chat_id,
      conversation_id,
      display_name: user.display_name,
      message: content,
    };

    setShowChatOptions(false);
    setChatReplyData(replyData);
  };

  const toggleShowChatOptions = () => {
    setShowChatOptions((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowChatOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const hasAttachment = message_attachment.length > 0;

  const FINAL_RESOURCES_URL = process.env.REACT_APP_CHAT_RESOURCES_URL;

  const attachmentType = hasAttachment ? message_attachment[0].file_type : null;
  const attachmentLink = hasAttachment ? `${FINAL_RESOURCES_URL}${message_attachment[0].attachment}` : null;

  const isImageAttachment = attachmentType && attachmentType.includes("image");
  const isFileAttachment = attachmentType && attachmentType.includes("pdf");
  const isVideoAttachment = attachmentType && attachmentType.includes("video");

  const userDisplayName = userDetails.display_name;
  const isReplyingToOwn = reply_to_message_detail?.user?.display_name === userDisplayName;
  const isReplyingToThemselves = reply_to_message_detail?.user?.display_name === user?.display_name;

  const profileImage =
    user.profile_photo === "" || user.profile_photo === null
      ? emptyImage
      : `${process.env.REACT_APP_IMAGES_DATA_RESOURCES_URL}/${user.profile_photo}`;
  return (
    <div>
      <div className='message__item-container message__item-container--receiver'>
        <div className='message__item-container-2'>
          <img className='message__item-img' src={profileImage} alt='Seller image' />

          <div className='message__item-message'>
            {reply_to_message_detail && (
              <div className='message__item-container-reply--receiver'>
                <span className='message__item-container-reply--detail'>
                  {user?.display_name} replied to{" "}
                  {isReplyingToOwn
                    ? "you"
                    : isReplyingToThemselves
                    ? "themselves"
                    : reply_to_message_detail.user.display_name}
                </span>
                <span className='message__item-container-reply-detail--receiver'>
                  {reply_to_message_detail.is_deleted ? "DELETED CHAT" : trimWord(reply_to_message_detail.content, 25)}
                </span>
              </div>
            )}
            <span className='message__item-details'>
              {!reply_to_message_detail && <span className='message__item-name'>{user?.display_name}</span>}
              {/* <span className='message__item-time'>{convertTo12HourFormat(createdAt)}</span> */}
            </span>

            <div>
              <div className='message__item-text-container-receiver' id={chat_id}>
                <span
                  className={`message__item-text message__item-text--receiver ${
                    is_deleted ? "message__item-deleted" : ""
                  }`}
                  onMouseEnter={(e) => {
                    if (e.target.nextElementSibling) {
                      e.target.nextElementSibling.style.display = "flex";
                    }
                  }}
                  onMouseLeave={(e) => {
                    if (e.target.nextElementSibling) {
                      e.target.nextElementSibling.style.display = "none";
                    }
                  }}
                >
                  <MessageComponent message={content} is_deleted={is_deleted} />
                  {!is_deleted &&
                    hasAttachment &&
                    (isImageAttachment ? (
                      <img
                        src={attachmentLink}
                        alt='Attachment'
                        className='message__item-attachment'
                        onClick={() => handleImageClick([{ src: attachmentLink, type: "image" }])}
                        style={{ cursor: "pointer" }}
                      />
                    ) : isVideoAttachment ? (
                      <video
                        controls
                        src={attachmentLink}
                        className='message__item-attachment'
                        onClick={() =>
                          handleImageClick([{ type: "video", sources: [{ src: attachmentLink, type: "video/mp4" }] }])
                        }
                        style={{ cursor: "pointer" }}
                      >
                        Your browser does not support the video tag.
                      </video>
                    ) : isFileAttachment ? (
                      <div className='message__item-attachment'>
                        <a
                          href={attachmentLink}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='message__item-attachment-file'
                        >
                          Click to view the file
                        </a>
                      </div>
                    ) : (
                      // <div className='message__item-attachment'>Unsupported attachment type</div>
                      <div className='message__item-attachment'>
                        <a
                          href={attachmentLink}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='message__item-attachment-file'
                        >
                          Click to view the file
                        </a>
                      </div>
                    ))}
                </span>
                <span className={`message__item-text message__item-text--receiver message__item-time`}>
                  <p>{convertTo12HourFormat(createdAt)}</p>
                </span>
                {!is_deleted && (
                  <div className='message__item-dropdown reaction-option-emoji-btn' ref={dropdownRef}>
                    <button type='button ' onClick={toggleShowChatOptions}>
                      <i className='fa fa-ellipsis-h message__item-ellipsis'></i>
                    </button>
                    <div className={`message__item-dropdown-item ${isShowChatOptions ? "show" : ""}`}>
                      <button type='button' onClick={handleOnClickReply}>
                        Reply
                      </button>
                      <button type='button' onClick={(e) => Chat.handleThreadViewMsgId(chat_id)}>
                        Reply in thread
                      </button>
                    </div>
                  </div>
                )}
                <div className='message__item-dropdown'>
                  {showReactionOptionList && (
                    <ReactionOptions
                      receiver={true}
                      chat_id={chat_id}
                      reaction={groupedReaction}
                      userDetails={userDetails}
                      toggleShowReactionOptions={toggleShowReactionOptions}
                    />
                  )}
                  {!is_deleted && (
                    <button type='button' onClick={toggleShowReactionOptions} className='reaction-option-emoji-btn'>
                      <img src={emojiReaction} className='reaction-option-emoji' />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='move-right'>
          <div className='view-chat-reactions view-chat-reactions-receiver'>
            {Object.keys(groupedReaction).map((reaction, index) => (
              <ReactionsIndicator reaction={`${reaction}`} reaction_details={groupedReaction[reaction]} key={index} />
            ))}
          </div>
          {thread_replies.length > 0 && (
            <div className='view-thread-replies-receiver'>
              <small className='thread-indicator' onClick={(e) => Chat.handleThreadViewMsgId(chat_id)}>
                {thread_replies.length} Thread Replies
              </small>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MessageReceiver;
