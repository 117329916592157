import React, { useState, useEffect } from "react";
import emptyImage from "../images/empty.jpg";
import ApiService from "../api/apiService";
import showSwal from "./swal";
import { convertTo12HourPlusDateFormat } from "../util/helperFunctions";
import { common } from "../util/common";

function ViewActivityLogsModal({ conversationData, showHideViewActivityLogsModal, userDetails }) {
  const conversation_id = conversationData?.id;
  const conversation_name = conversationData?.name;

  const [activityLogs, setActivityLogs] = useState([]);

  const fetchFilesData = async () => {
    try {
      if (conversation_id) {
        const response = await ApiService.conversation.getConversationActionByConversationId(conversation_id);
        const { success, conversation_actions } = response;

        if (!success) return showSwal(common.errors.FETCH_ACTIVITY_LOGS_ERROR);

        setActivityLogs(conversation_actions);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchFilesData();
    return () => {};
  }, []);

  const sortedLogs = activityLogs.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));

  const conversationAction = (item, userDetails) => {
    const { user, createdAt, action, receiving_user_action } = item;

    let actionSender = user.display_name;
    let actionReceiver = receiving_user_action.display_name;
    let actionDescription;
    if (user.id === userDetails.id) actionSender = "You";
    if (receiving_user_action.id === userDetails.id) actionReceiver = "you";

    switch (action) {
      case "MUTE":
        actionDescription = `${actionSender} have muted/unmuted ${actionReceiver}`;
        break;
      case "ADD":
        actionDescription = `${actionSender} have added ${actionReceiver} to the chat`;
        break;
      case "REMOVE":
        actionDescription = `${actionSender} removed ${actionReceiver} from the chat`;
        break;
      case "LEAVE":
        actionDescription = `${actionSender} has left`;
        break;
      case "DELETE":
        actionDescription = `${actionSender} deleted a chat.`;
        break;
      case "CREATE":
        actionDescription = `${actionSender} created a group chat.`;
        break;
      case "UPDATE":
        actionDescription = `${actionSender} updated the group chat.`;
        break;
      default:
        actionDescription = `Your conversation has been experiencing problems, please contact support.`;
        break;
    }

    const profileImage =
      user.profile_photo === "" || user.profile_photo === null
        ? emptyImage
        : `${process.env.REACT_APP_IMAGES_DATA_RESOURCES_URL}/${user.profile_photo}`;

    return (
      <div className='conversation-actions'>
        <img src={profileImage} className='conversation-action-user-photo' />
        <div className='conversation-action-desc'>
          <small>&nbsp; {actionDescription}</small>
          <small>&nbsp; {convertTo12HourPlusDateFormat(createdAt)}</small>
        </div>
      </div>
    );
  };
  return (
    <div className='modal-container'>
      <div className='modal-dialog'>
        <button className='close-btn' onClick={showHideViewActivityLogsModal}>
          ✕
        </button>
        <h3>View Activity Logs in {conversation_name}...</h3>

        <ul className='view-members-list'>
          <li>
            <div className='result-container view-member-container-header'></div>
          </li>
          {sortedLogs.length > 0 ? (
            sortedLogs.map((log) => <li key={log.id}>{conversationAction(log, userDetails)}</li>)
          ) : (
            <li>
              <span className='no-chats-found'>NO ACTIVITY LOGS FOUND</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default ViewActivityLogsModal;
