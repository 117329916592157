function Offsetting({ offset, addOffsetting, removeOffsetting }) {
  return (
    <div className='offsetting--container'>
      <div className='options--container'>
        <i className='fa fa-plus-circle fa-3x' aria-hidden='true' onClick={(e) => addOffsetting()}></i>
        <i className='fa fa-trash fa-3x' aria-hidden='true' onClick={(e) => removeOffsetting(offset.id)}></i>
      </div>
      <div className='offsetting-field'>
        <label htmlFor=''>
          Date of Extended Hours <span className='required'>*</span>
        </label>
        <input type='date' name='' id='' />
      </div>
      <div className='offsetting-field'>
        <label htmlFor=''>
          Accumulated Hours <span className='required'>*</span>
        </label>
        <select name='' id=''>
          <option value=''>2 Hours</option>
          <option value=''>3 Hours</option>
          <option value=''>4 Hours</option>
          <option value=''>5 Hours</option>
        </select>
      </div>
    </div>
  );
}

export default Offsetting;
