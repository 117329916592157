import React, { useState, useContext, useEffect } from "react";
import "../styles/style.css";
import BuildHubLogo from "../images/buildhub.png";
import BuildHubLogoSmall from "../images/buildhub-small.png";
import { userLogout } from "../hooks/accountHandler";
import { useNavigate } from "react-router-dom";
import NotificationBell from "./reusable/notification-bell";
import { ChatContext } from "../context/contexts";

function Header(props) {
  const Chat = useContext(ChatContext);
  const { userDetails, showHideChangePasswordModal, inProfile } = props;
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isMobileMode, setMobileMode] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      const maxWidth = 120 * parseFloat(getComputedStyle(document.documentElement).fontSize);
      setMobileMode(window.innerWidth <= maxWidth);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleLogout = () => {
    userLogout();
    navigate("/login");
  };

  return (
    <header className='header'>
      <a href='/'>
        <img src={isMobileMode ? BuildHubLogoSmall : BuildHubLogo} alt='Logo' className='logo' />
      </a>
      <h1 className='title heading-1'>&nbsp; | Chat</h1>

      <div className='profile-nav'>
        <NotificationBell />
        <div className='profile-container'>
          <button className='profile js-dropdown-btn' onClick={toggleDropdown}>
            <p className='profile__name'>{userDetails?.display_name}</p>
            <i className='fa fa-caret-down'></i>
          </button>
          <ul className={`selection ${dropdownVisible ? "" : "u-hidden"} js-dropdown`}>
            <li className='selection__list'>
              <button className='btn-logout' onClick={(e) => navigate("/profile")}>
                My Profile
              </button>
            </li>
            <li className='selection__list'>
              <button className='btn-logout' onClick={(e) => navigate("/requests?p=view-requests")}>
                My Requests
              </button>
            </li>
            {!inProfile && (
              <li className='selection__list'>
                <button className='btn-logout' onClick={showHideChangePasswordModal}>
                  Change Password
                </button>
              </li>
            )}
            <li className='selection__list'>
              <button className='btn-logout' onClick={handleLogout}>
                Logout
              </button>
            </li>
          </ul>
        </div>

        {/* <div className='header-icons'></div> */}
      </div>
    </header>
  );
}

export default Header;
