import io from "socket.io-client";

class Websocket {
  static instance;
  socket;
  userId;
  displayName;
  roomId;

  constructor() {
    if (!Websocket.instance) {
      Websocket.instance = this;
    }
    return Websocket.instance;
  }

  initConnection(userId, displayName) {
    if (!this.socket) {
      this.socket = io(process.env.REACT_APP_API_URL);
      this.userId = userId;
      this.displayName = displayName;
      this.setupVisibilityChangeListener();

      // this.initSidebarRooms();
    }
  }

  setupListeners(onMessageReceived) {
    this.socket.on("message", (data) => {
      if (
        (data && (data.type === "user-chat" || data.type === "user-actions-chat")) ||
        data.type === "user-thread-chat" ||
        data.type === "user-reactions-chat"
      ) {
        onMessageReceived(data.message_detail);
      }
    });
    this.socket.on("messageReaction", (data) => {
      if (data && data.type === "user-reactions-chat") {
        onMessageReceived(data.message_detail);
      }
    });
  }
  setupDisconnectListener() {
    this.socket.on("disconnect", () => {
      console.log("Socket disconnected");
      this.roomId = null;
    });
  }

  getConnection() {
    return this.socket;
  }

  isConnectedRoom() {
    return this.socket && this.roomId ? { connected: true, socket: this.socket } : { connected: false };
  }

  connectRoom(roomId) {
    this.disconnectRoom();
    if (this.socket) {
      this.roomId = roomId;
      this.socket.emit("join", { userId: this.userId, name: this.displayName, room: roomId }, (error) => {
        if (error) {
          // alert(error);
        }
      });
    }
  }

  sendMessage(messageDetail) {
    if (this.socket) {
      this.socket.emit("sendMessage", {
        message: messageDetail,
        room: messageDetail.message.conversation_id,
        type: messageDetail.type,
      });
      this.socket.emit("sidebar_sendMessage", {
        message: messageDetail,
        room: messageDetail.message.conversation_id,
        type: "user-sidebar-chat",
      });
    }
  }

  sendMsgReaction(reactionDetail, conversation_id) {
    if (this.socket) {
      this.socket.emit("sendMsgReaction", {
        message: reactionDetail,
        room: conversation_id,
        type: reactionDetail.type,
      });
    }
  }

  disconnectRoom() {
    if (this.socket && this.roomId) {
      this.socket.emit("leave", { userId: this.userId, roomId: this.roomId }, (error) => {
        if (error) {
          alert(error);
        }
      });
      this.roomId = null;
    }
  }

  checkConnection() {
    if (!this.socket || !this.socket.connected) {
      window.location.reload();
    }
  }
  startConnectionCheck() {
    setInterval(() => this.checkConnection(), 5000);
  }

  setupVisibilityChangeListener() {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        if (!this.socket.connected) {
          this.socket.connect();
        }
      }
    });
  }
  // SIDE BAR WS
  // initSidebarRooms() {
  //   if (this.socket) {
  //     this.socket.emit("sidebar_join", { userId: this.userId, name: this.displayName }, (error) => {
  //       if (error) {
  //         alert(error);
  //       }
  //     });
  //   }
  // }

  // setupSidebarListeners(onMessageReceived) {
  //   this.socket.on("sidebar_message", (data) => {
  //     if (data && data.type === "user-sidebar-chat") {
  //       onMessageReceived(data);
  //     }
  //   });
  // }
}

export default Websocket;
